import React, { useState } from "react"
import { FaAddressBook, FaPhone, FaLocationArrow, FaTimes } from "react-icons/fa";
import { SiUbereats, SiDeliveroo } from "react-icons/si";
import { RiTakeawayLine } from "react-icons/ri"
import { MdShoppingBasket } from "react-icons/md"
import Modal from 'react-modal';


export default function FooterMenu({ data }) {
  const [iframe, showIframe] = useState(false);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%',
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.7)',
    }
  };
  return (
    <div className="footer-menu">
      {data.restaurant.urlName && data.restaurant.websiteSettings?.reservation && (
        <div role="button" tabIndex={0} onClick={() => showIframe(!iframe)} onKeyDown={() => showIframe(!iframe)} data-item="Réservation"><FaAddressBook size="1.8em" />Reservation</div>
      )}

      {data.restaurant.settings?.delivery && (
        <a href={`https://order.we-eats.com/${data.restaurant.urlName}/livraison`} target="_blank" rel="noreferrer">
          <div><RiTakeawayLine size="1.8em" />Livraison</div>
        </a>
      )}

      {data.restaurant.settings?.takeaway && (
        <a href={`https://order.we-eats.com/${data.restaurant.urlName}`} target="_blank" rel="noreferrer">
          {(data.restaurant.urlName === 'wafu-brumath' || data.restaurant.urlName === 'le-mandarin-traiteur-asiatique') && (
            <div><MdShoppingBasket size="1.8em" />Emporter</div>
          )}
          {data.restaurant.urlName !== 'wafu-brumath' && data.restaurant.urlName !== 'le-mandarin-traiteur-asiatique' && (
            <div><MdShoppingBasket size="1.8em" />Click & Collect</div>
          )}
        </a>
      )}

      {data.restaurant.websiteSettings?.uberEatsUrl && (
        <a href={`${data.restaurant.websiteSettings?.uberEatsUrl}`} target="_blank" rel="noreferrer">
          <div><SiUbereats size="1.8em" />Livraison</div>
        </a>
      )}

      {data.restaurant.websiteSettings?.deliverooUrl && (
        <a href={`${data.restaurant.websiteSettings?.deliverooUrl}`} target="_blank" rel="noreferrer">
          <div><SiDeliveroo size="1.8em" />Livraison</div>
        </a>
      )}

      {data.restaurant.websiteSettings?.googleMapsUrl && (!data.restaurant.websiteSettings?.uberEatsUrl || !data.restaurant.websiteSettings?.deliverooUrl) && (
        <a href={`${data.restaurant.websiteSettings?.googleMapsUrl}`} target="_blank" rel="noreferrer">
          <div><FaLocationArrow size="1.8em" />Itinéraire</div>
        </a>
      )}

      {data.restaurant.urlName === 'mamagaya-drancy' && (
        <a href={`${data.restaurant.websiteSettings?.googleMapsUrl}`} target="_blank" rel="noreferrer">
          <div><FaLocationArrow size="1.8em" />Itinéraire</div>
        </a>
      )}

      <div><a href={`tel:${data.restaurant.informations.phone}`}><div><FaPhone size="1.8em" /></div>Appeler</a></div>

      <Modal
        isOpen={iframe}
        onRequestClose={() => showIframe(!iframe)}
        style={{ ...customStyles, zIndex: 1 }}
        contentLabel="Reservation"
      >
        <iframe src={`https://reservation.we-eats.com/${data.restaurant.urlName}`} width="100%" height="100%" frameBorder="0" title="réservation" />
        <span className="close-btn" tabIndex={0} onClick={() => showIframe(!iframe)} onKeyDown={() => showIframe(!iframe)} role="button">
          <FaTimes color="white" size="1.8em" />
        </span>
      </Modal>
    </div>

  )
}
