import React from "react"
import TopMenu from "../components/top-menu"
import FooterMenu from "../components/footer-menu"
import SideBar from "../components/sidebar"
import useResponsive from '../utils/useResponsive'



export default function Layout({ children, data }) {
  return (
    <div>
      {useResponsive().isDesktop && (
        <SideBar data={data} />
      )}
      {useResponsive().isMobile && (
        <TopMenu data={data} />
      )}
      {children}
      {useResponsive().isMobile && (
        <FooterMenu data={data} />
      )}
    </div>
  )
}