import React, { useState } from "react"
import { Link } from "gatsby"
import { FaInstagram, FaYelp, FaTwitter, FaFacebook, FaTripadvisor, FaFoursquare } from "react-icons/fa";
import { HiMenu, HiOutlineX } from "react-icons/hi";
import '../styles/top-menu.scss'

export default function TopMenu({ children, data }) {
  const [open, setOpen] = useState(false)

  return (
    <div className="top-menu">
      <div onClick={() => setOpen(true)} onKeyDown={() => setOpen(true)} role="button" tabIndex={0} aria-label="Open menu" ><HiMenu size="2em" color="white" /></div>

      <nav className={`menu ${open ? `open` : ""}`} id="main-nav">
        <div onClick={() => setOpen(false)} onKeyDown={() => setOpen(true)} role="button" tabIndex={0} aria-label="Close menu" ><HiOutlineX size="2em" /></div>

        <Link to="/">ACCUEIL</Link>
        <Link to="/information/">INFORMATION</Link>
        <Link to="/menu/">MENU</Link>
        <Link to="/avis/">AVIS</Link>


        <div className="social-logo">
          {data.restaurant.websiteSettings?.linkInstagram &&
            <a href={`${data.restaurant.websiteSettings?.linkInstagram}`} target="_blank" rel="noreferrer"><FaInstagram size="2em" /> </a>
          }
          {data.restaurant.websiteSettings?.linkTwitter &&
            <a href={`${data.restaurant.websiteSettings?.linkTwitter}`} target="_blank" rel="noreferrer"><FaTwitter size="2em" /> </a>
          }
          {data.restaurant.websiteSettings?.linkFacebook &&
            <a href={`${data.restaurant.websiteSettings?.linkFacebook}`} target="_blank" rel="noreferrer"><FaFacebook size="2em" /> </a>
          }
          {data.restaurant.websiteSettings?.linkTripadvisor &&
            <a href={`${data.restaurant.websiteSettings?.linkTripadvisor}`} target="_blank" rel="noreferrer"><FaTripadvisor size="2em" /> </a>
          }
          {data.restaurant.websiteSettings?.linkFoursquare &&
            <a href={`${data.restaurant.websiteSettings?.linkFoursquare}`} target="_blank" rel="noreferrer"><FaFoursquare size="2em" /> </a>
          }
          {data.restaurant.websiteSettings?.linkYelp &&
            <a href={`${data.restaurant.websiteSettings?.linkYelp}`} target="_blank" rel="noreferrer"><FaYelp size="2em" /> </a>
          }
        </div>

        <div className="footer">
          <p>{`© ${new Date().getFullYear()} ${data.restaurant.informations.name}`}<br />
          Site realisé par <a href="https://we-eats.com" target="_blank" rel="noreferrer">WE EATS</a>
          </p>
        </div>
      </nav>

    </div>
  )
}
