import React, { useState } from "react"
import { Link } from "gatsby"
import Modal from 'react-modal'

import { FaPhone, FaLocationArrow, FaInstagram, FaYelp, FaTwitter, FaFacebook, FaTripadvisor, FaFoursquare, FaTimes } from "react-icons/fa";

export default function Sidebar({ data }) {
  const [iframe, showIframe] = useState(false);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.7)',
    }
  };

  return (
    <div className="sidebar">
      {data.restaurant.websiteSettings?.logo && (
        <Link to="/">
          <img src={`${data.restaurant.websiteSettings?.logo[0].path}`} alt="logo" />
        </Link>
      )}
      <nav>
        <Link to="/">ACCUEIL</Link>
        <Link to="/information/">INFORMATION</Link>
        <Link to="/menu/">CARTE</Link>
        <Link to="/avis/">AVIS</Link>
      </nav>
      <div className="actions">
        {data.restaurant.urlName && data.restaurant.websiteSettings?.reservation && (
          <a href={'##'} onClick={() => showIframe(!iframe)} data-item="Réservation">
            <div className="action-button">Réservation</div>
          </a>
        )}

        {data.restaurant.settings?.delivery && (
          <a href={`https://order.we-eats.com/${data.restaurant.urlName}/livraison`} target="_blank" rel="noreferrer">
            <div className="action-button">Livraison {data.restaurant.urlName === 'mamagaya-pizza-paris19' ? 'Mamagaya': '' }</div>
          </a>
        )}

        {data.restaurant.settings?.takeaway && (
          <a href={`https://order.we-eats.com/${data.restaurant.urlName}`} target="_blank" rel="noreferrer">
            {(data.restaurant.urlName === 'wafu-brumath' || data.restaurant.urlName === 'le-mandarin-traiteur-asiatique') && (
              <div className="action-button">Emporter</div>
            )}
            {data.restaurant.urlName !== 'wafu-brumath' && data.restaurant.urlName !== 'le-mandarin-traiteur-asiatique' && (
              <div className="action-button">Click & Collect</div>
            )}
          </a>
        )}

        {data.restaurant.websiteSettings?.uberEatsUrl && (
          <a href={`${data.restaurant.websiteSettings?.uberEatsUrl}`} target="_blank" rel="noreferrer">
            <div className="action-button">Livraison (Uber eats)</div>
          </a>
        )}

        {data.restaurant.websiteSettings?.deliverooUrl && (
          <a href={`${data.restaurant.websiteSettings?.deliverooUrl}`} target="_blank" rel="noreferrer">
            <div className="action-button">Livraison (Deliveroo)</div>
          </a>
        )}

      </div>
      <div className="adresse">
        <p>
          {data.restaurant.websiteSettings?.googleMapsUrl && (
            <a href={`${data.restaurant.websiteSettings?.googleMapsUrl}`} target="_blank" rel="noreferrer">
              <FaLocationArrow />&nbsp;&nbsp;&nbsp;Adresse<br />
              {data.restaurant.informations.address}<br />
              {data.restaurant.informations.postalCode} {data.restaurant.informations.city}
            </a>
          )}
          {!data.restaurant.websiteSettings?.googleMapsUrl && (
            <>
              <FaLocationArrow />&nbsp;&nbsp;&nbsp;Adresse<br />
              {data.restaurant.informations.address}<br />
              {data.restaurant.informations.postalCode} {data.restaurant.informations.city}
            </>
          )}
          <br /><br />
          <FaPhone /><a href={`tel:${data.restaurant.informations.phone}`}>&nbsp;&nbsp;&nbsp;{data.restaurant.informations.phone}</a>
        </p>
      </div>

      <div className="social-logo">
        {data.restaurant.websiteSettings?.linkInstagram &&
          <a href={`${data.restaurant.websiteSettings?.linkInstagram}`} target="_blank" rel="noreferrer"><FaInstagram size="2em" /> </a>
        }
        {data.restaurant.websiteSettings?.linkTwitter &&
          <a href={`${data.restaurant.websiteSettings?.linkTwitter}`} target="_blank" rel="noreferrer"><FaTwitter size="2em" /> </a>
        }
        {data.restaurant.websiteSettings?.linkFacebook &&
          <a href={`${data.restaurant.websiteSettings?.linkFacebook}`} target="_blank" rel="noreferrer"><FaFacebook size="2em" /> </a>
        }
        {data.restaurant.websiteSettings?.linkTripadvisor &&
          <a href={`${data.restaurant.websiteSettings?.linkTripadvisor}`} target="_blank" rel="noreferrer"><FaTripadvisor size="2em" /> </a>
        }
        {data.restaurant.websiteSettings?.linkFoursquare &&
          <a href={`${data.restaurant.websiteSettings?.linkFoursquare}`} target="_blank" rel="noreferrer"><FaFoursquare size="2em" /> </a>
        }
        {data.restaurant.websiteSettings?.linkYelp &&
          <a href={`${data.restaurant.websiteSettings?.linkYelp}`} target="_blank" rel="noreferrer"><FaYelp size="2em" /> </a>
        }
      </div>

      <div className="footer">
        <p>{`© ${new Date().getFullYear()} ${data.restaurant.informations.name}`}<br />
        Site realisé par <a href="https://we-eats.com" target="_blank" rel="noreferrer">WE EATS</a>
        </p>
      </div>

      <Modal
        isOpen={iframe}
        onRequestClose={() => showIframe(!iframe)}
        style={customStyles}
        contentLabel="Reservation"
        ariaHideApp={false}
      >
        <iframe src={`https://reservation.we-eats.com/${data.restaurant.urlName}`} width="500" height="800" frameBorder="0" title="réservation" />
        <span className="close-btn" onClick={() => showIframe(!iframe)} onKeyDown={() => showIframe(!iframe)} role="button" tabIndex={0}>
          <FaTimes color="white" size="1.8em" />
        </span>

      </Modal>
    </div >
  )
}

